<template>
    <div class="container-full banner-home">
        <div>
            <img class="z-index-1" alt="Vue logo" src="../assets/simbolo.svg">
        </div>
        <div class="container-banner-home">
            <p class="p-banner-home hl-25">
                Tierra Fértil es un proyecto inmobiliario que se enfoca en ofrecer opciones confiables y solidas a sus clientes para desarrollar sus proyectos de inversión y planes de vida, acompañados por un equipo de profesionales y especialistas avocados a desarrollar su proyecto ideal.

                Nos dedicamos a la venta de terrenos para casas de campo. Actualmente hemos vendido más de 30.000 m2 a más de 40 familias felices por haber cumplido sus sueños.
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name:'BannerHomeComponent'    
}
</script>
<style scoped>
    .banner-home{
        background-color: #415B37;
        height: 250px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        color: white;
    }     
    .container-banner-home{
        width: 850px;
        z-index: 2;
        /* background-color: rgba(169, 169, 169, 0.322); */

    }
    .banner-home img{
        width: 450px;
        position: absolute;   
        left: -150px;
        top: -45px;
    }
    .p-banner-home{
        font-weight: 200!important;
        line-height: 35px;
    }
    @media (max-width:900px){
        .banner-home{
            height: 400px;
        }
    }
       @media (max-width:500px){
        .banner-home{
            height: 480px;
        }
    }
</style>