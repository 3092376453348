<template>
    <div class="container-arqui">
        <img v-if="selected_model.main_img" class="brightness main-project" alt="Projectos Tierra fertil" :src="getImgUrl(selected_model.main_img)">
        <transition-group name="models-list" tag="div" class="model-container">
            <div v-for="(model,index) in models" :key="model.id" @click="change_index(index)">
                <p>{{model.subtitle}}</p>
                <h2>{{model.title}}</h2>
            </div>
        </transition-group>
        <!--<div class="img-model-container">
            <div v-for="plan in selected_model.plans" :key="plan.img">
                <img class="w-100" alt="Vue logo" :src="getImgUrlPng(plan.img)">
            </div>
        </div>-->
        <div class="content-description">
            {{ selected_model.subtitle }}
            <h3 class="text-uppercase">{{ selected_model.title }}</h3>
            <div class="p-container" v-html="selected_model.description"></div>
        </div>
        
    </div>
    <div>
        <slider-component :gallery="selected_model.gallery"></slider-component>
    </div>
</template>

<script>

import { ref,onMounted } from 'vue'
import SliderComponent from '../../components/projects/SliderComponent.vue'

    export default {
        components:{
            SliderComponent,
        },
        setup(){
            const models = ref([
                {   
                    id:1,
                    subtitle:'VIVIENDA 1',
                    title:'Integración a lo natural' ,
                    main_img:'main-project',
                    description:'<p>La idea de este proyecto, se trabajó bajo el concepto de “espacio en la naturaleza”, esto nos llevó a reflexionar sobre el tipo de arquitectura que propondríamos en entornos naturales dentro de los valles y pampas de Cusco. Las ideas básicas del diseño de la casa fueron mantener la armonía en el entorno natural de la comunidad de Simataucca y plantear un espacio contemporáneo al interior. La casa está compuesta por dos volúmenes perpendiculares y atravesada por un corredor de madera iluminado naturalmente. Los techos altos revestidos con madera de la zona, generan imponencia, teniendo caída de una agua. </p><p>Esta casa que ofrece un escape a la naturaleza cuenta con una amplia sala, cocina y comedor integrados, 2 dormitorios, 2 baños completos y un área de estar exterior que comparte chimenea con el interior.</p>',
                    plans:[
                        {
                            img:'model1'
                        },
                        {
                            img:'model2'
                        },
                        {
                            img:'model3'
                        },
                    ],
                    gallery:[
                        {
                            id:1,
                            img_name:'slider-model-1'
                        },
                        {
                            id:2,
                            img_name:'slider-model-2'
                        },
                        {
                            id:3,
                            img_name:'slider-model-3'
                        }
                    ]
                },
                {   
                    id:2,
                    subtitle:'VIVIENDA 2',
                    title:'Nucleo familiar',
                    main_img:'project-ayarmaca1',
                    description:'<p>Situada para estar en armonía con su rico paisaje, esta casa construida y diseñado para ser un espacio confortable para una familia de 6 miembros, aprovecha todas las oportunidades para disfrutar de las vistas de los valles y montañas de Simataucca. Su arte se extiende a los materiales usados, la delicadeza del vidrio, la dureza de la madera y el contacto con la tierra a través del adobe. La idea del refugio de una familia en los valles de Cusco fue clave para el diseño de interiores, las amplias mamparas ofrecen una vista directa a los valles y al nevado Chicón. Interiormente la casa brinda mucha calidez con el juego de materiales y la madera como protagonista principal. A la casa se conecta una zona de terraza y piscina cubierta por una estructura de madera y paneles de vidrio, haciendo que la construcción se integre con la vegetación circundante.  </p><p>Esta amplia casa de 240m2 cuenta con 6 habitaciones, 4 baños, sala, cocina y comedor integrados, bar, lavandería, zona de parrilla con horno y área de terraza con piscina.  </p>',
                    plans:[
                        {
                            img:'model1'
                        },
                        {
                            img:'model2'
                        },
                        {
                            img:'model3'
                        },
                    ],
                     gallery:[
                        {
                            id:4,
                            img_name:'slider-ayarmaca-1'
                        },
                        {
                            id:5,
                            img_name:'slider-ayarmaca-2'
                        },
                        {
                            id:6,
                            img_name:'slider-ayarmaca-3'
                        }
                    ]
                },
                {   
                    id:3,
                   subtitle:'VIVIENDA 3',
                    title:'Tradición',
                    main_img:'project-maras1',
                    description:'<p>Este proyecto se basa en la tradición, en un espacio de contacto con el entorno natural. El lenguaje arquitectónico es puro y elemental, ajustado a los constructores y estética local. Usando materiales eco amigables y autóctonos de la zona como: piedra, adobe, madera de eucalipto y teja andina.  </p><p>La simplicidad de la arquitectura desempeña un papel importante al integrar la casa al tipo de construcción local, tornándose parte de la comunidad de Ayarmaca. La propuesta de vivienda está proyectada en 2 plataformas situadas en una montaña, aprovechando así la topografía de esta. La piedra juega un papel importante en las áreas exteriores de la casa, este material es el intérprete en las áreas sociales de parrilla, fogata y las andenerías.</p><p>Este proyecto es un lugar para conectarse con lo simple y con la naturaleza. Cuenta con 3 habitaciones, 3 baños, sala de estudio, sala y comedor integrados, cocina con salida a un patio trasero y lavandería. En las áreas exteriores se ubican el estacionamiento, área de fogata, zona de andenerías, zona de parrilla y un mirador elevado que posee una vista al nevado Chicón.</p>',
                    plans:[
                        {
                            img:'model1'
                        },
                        {
                            img:'model2'
                        },
                        {
                            img:'model3'
                        },
                    ],
                     gallery:[
                        {
                            id:7,
                            img_name:'slider-maras-1'
                        },
                        {
                            id:8,
                            img_name:'slider-maras-2'
                        },
                        {
                            id:9,
                            img_name:'slider-maras-3'
                        }
                    ]
                },
            ])
            const selected_model = ref({});
            function change_index(index) {
                selected_model.value = models.value[index]
               index
               if (index == 0){
                   let ultimo = models.value.shift();
                   models.value.splice(1,0,ultimo)
               }
               else{
                    var limit = index-2;
                    for (var i=0;i<=limit;i++) {
                    (function(ind) {
                        setTimeout(function(){
                            let ultimo = models.value.shift();
                            //console.log(ultimo)
                            models.value.push(ultimo)
                            console.log(ind);
                            if(ind === limit){
                                console.log('It was the last one');
                            }
                        }, 50 + (400 * ind));
                    })(i);
                    
                    }
               }
           }
           function getImgUrl(pet) {
                var images = require.context('../../assets/projects/', false, /\.jpg$/)
                return images('./' + pet + ".jpg")
            }
             function getImgUrlPng(pet) {
                var images = require.context('../../assets/projects/', false, /\.png$/)
                return images('./' + pet + ".png")
            }
            onMounted(() => {
                selected_model.value = models.value[1];
            })
            return{
                getImgUrl,
                models,
                change_index,
                selected_model,
                getImgUrlPng,
            }
        }
    }
</script>

<style scoped>
    .brightness{
        filter:brightness(0.5)
    }
    .text-uppercase{
        text-transform: uppercase;
    }
    .models-list-move {
        opacity: 0.2;
        transition: transform 0.5s ease-out;
    }
    .container-arqui{
        position: relative;
        margin: 80px 230px;
        display: grid;
        justify-content: center;
    }
    .w-100{
        width: 100%;
    }
    
    .main-project{
      width: 950px; 
      height: inherit;
      object-fit: cover;
    }
    .model-container{
        margin-top: -30px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        gap: 5px;
        text-align: center;
    }
    .model-container>div{
        height: 60px;
        /* border-left:1px solid black  ; */
        background-color: rgb(207, 207, 207);
        box-shadow: 2px 2px 5px black;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .model-container h2 {
        margin:-20px 0 0 0 ;
        font-size: 20px;
        
    }
    .model-container p{
        margin: 0;
    }
    .model-container>div:nth-child(2){
        /*scale: 1.2;*/
        -webkit-transform: scale( 1.2);
        -moz-transform: scale( 1.2);
        -o-transform: scale( 1.2);
        transform: scale( 1.2);
        background: rgb(230, 230, 230);
    }
    .img-model-container{
        /* background: chartreuse; */
        right: 0;
        left: 0;
        width: 750px;
        margin: 0 auto;
        position: absolute;
        top: 120px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 25px;
        justify-content: center;
    }
    .content-description{
        position: absolute;
        top: 100px;
        width: 550px;
        right: 0;
        left: 0;
        color: white;
        margin: 0 auto;
    }   


    @media (max-width: 1350px){
      .p-container{
        height: 510px;
        overflow: overlay;
        padding-right: 13px;
        }  
    }

    


    @media (max-width: 1350px){
         .model-container{
            grid-template-columns: 1fr;
            width: 90%;
            margin-top: -65px;
        }
    }
    @media (max-width: 1000px){
        .container-arqui{
            display: grid;
            justify-content: center;
            margin: 110px 25px 15px 25px;
            height: 870px;
        }
        .model-container{
            grid-template-columns: 1fr;
            width: 90%;
            margin-top: -65px;
        }
        .model-container>div:nth-child(2){
            /*scale: 1.05;*/
            -webkit-transform: scale( 1.05);
            -moz-transform: scale( 1.05);
            -o-transform: scale( 1.05);
            transform: scale( 1.05);
        }
        .img-model-container{
            top: 190px;
            width: 100%;
        }
        .content-description{
            width: 80%;
            padding: 0px 15px;
            top: 200px;
        }
        .main-project{
            width: 100%; 
            height: 100%;
        }
    }
   
</style>