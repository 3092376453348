import { createRouter, createWebHistory } from 'vue-router'
//import Home from '../views/Home.vue'
import Started from '../views/Started.vue'
import Test from '../views/Test.vue'
import Projects from '../views/Projects.vue'
import Contact from '../views/Contact.vue'
import Terrain from '../views/Terrain.vue'
import LandingPage from '../views/LandingPage.vue'

const routes = [
  {
    path: '/',
    name: 'Started',
    component: Started
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  
  {
    path: '/proyectos',
    name: 'Projects',
    component: Projects
  },
  
  {
    path: '/contacto',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/terrenos',
    name: 'Terrain',
    component: Terrain
  },
  {
    path: '/guia',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    to,from,savedPosition
    return { x: 0, y: 0 }
  }
  
})

router.beforeEach(function (to, from, next) { 
  setTimeout(() => {
      window.scrollTo(0, 0);
  }, 100);
  next();
});

export default router
