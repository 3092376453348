<template>
    <div>
        <img class="banner-header" alt="Projectos Tierra fertil" src="../assets/projects/banner.jpg">
        <div class="container-form">
            <h1>AGENDEMOS UNA REUNIÓN</h1>
            <p>Puedes contactarnos por todos los medios digitales, o tambien por este medio</p>
            
            <div class="content-form">
                <div class="box divide-two">
                    <div class="box-input">
                        <label for="name">NOMBRE *</label>
                        <input v-model="name" id="name" type="text">
                        <small v-if="void_input && name === ''" class="text-red">Este campo esta vacio</small>
                    </div>
                    <div class="box-input">
                        <label for="business">EMPRESA</label>
                        <input v-model="company" type="text">
                    </div>
                </div>
                <div class="box divide-two">
                    <div class="box-input">
                        <label for="">ASUNTO *</label>
                        <input v-model="issue" type="text">
                        <small v-if="void_input && issue === ''" class="text-red">Este campo esta vacio</small>
                    </div>
                    <div class="box-input">
                        <label for="phone">TELEFONO/CELULAR *</label>
                        <input v-model="telephone" id="phone" type="text">
                        <small v-if="void_input && telephone === ''" class="text-red">Este campo esta vacio</small>
                    </div>
                </div>
                <div class="box box-textarea">
                    <label for="menssage">MENSAJE*</label>
                    <textarea v-model="message" name="menssage" id="" cols="30" rows="10"></textarea>
                    <small v-if="void_input && telephone === ''" class="text-red">Este campo esta vacio</small>
                </div>
                <div class="btn-container">
                    <button @click="send_data">
                        ENVIAR
                    </button>
                </div>
                <p v-show="success_message" class="text-success">Tu mensaje se envio con éxito!!!</p>
            </div>
        </div>
        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15521.23549853485!2d-72.06439904819337!3d-13.45504781475306!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7f89cd27255c46e9!2sTierra%20f%C3%A9rtil%20-%20Bienes%20Ra%C3%ADces%20Cusco!5e0!3m2!1ses-419!2spe!4v1636648635139!5m2!1ses-419!2spe" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <!-- <div>
            {{ loading.data }} --{{ loading.success }}
        </div> -->
        <loading-component :enviado="loading"></loading-component>
    </div>

</template>

<script>
import {ref} from '@vue/reactivity'
import axios from 'axios'
import LoadingComponent from '../components/layout/LoadingComponent.vue'
    export default {
        components:{
            LoadingComponent
        },
        setup(){
            const name = ref('');
            const company = ref('');
            const issue = ref('');
            const telephone = ref('');
            const message = ref('');
            const void_input = ref(false);
            const success_message = ref(false);

            const loading = ref({data:false,success:false});//cargar la pantalla de cargar

            function send_data(){
                if (name.value === '' || 
                issue.value === '' || 
                telephone.value === '' || 
                message === ''
                ){
                    console.log('esta vacios')
                    void_input.value = true;
                }else{
                    loading.value.data = true;
                    const headers = {
                    'Authorization': 'Bearer my-token',
                    'My-Custom-Header': 'foobar'
                    };
                    axios.post('https://api.tierrafertil.pe/api/register-contact',{
                        name:name.value,
                        company:company.value,
                        issue:issue.value,
                        telephone:telephone.value,
                        message:message.value
                    },{headers})
                    .then(response => {
                        console.log(response+'listo')
                        loading.value.data = false;
                        loading.value.success = true;
                        success_message.value = true;
                        setTimeout(function () { loading.value.success = false; }.bind(this), 3000)
                        }
                        
                    )
                    .catch(error => {
                        `Error: ${error.message}`;
                        console.error('There was an error!', error);
                    });
                }
                
            }
            function toggle_loading(){
                loading.value = !loading.value
            }
            return{
                loading,
                name,
                company,
                issue,
                telephone,
                message,
                send_data,
                toggle_loading,
                void_input,
                success_message
            }
        }
    }
</script>

<style scoped>
    .text-success{
        text-align: center;
        font-size: 19px;
        font-weight: 500;
    }
    .text-red{
        color: rgb(255, 158, 158);
    }
    textarea, input{
        font-family: 'Montserrat', sans-serif;
    }
    .container-form{
        width: 700px;
        margin: 50px auto;
    }
    .banner-header{
        height: 450px;
        width: 100%;
        object-fit: cover;
        object-position: bottom;
    }
    .divide-two{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
    .box{
        margin: 20px 0;
    }
    .box-input label{
        widows: 100%;
    }
    .box-input input{
        width: 100%;
    }
    .box-textarea textarea{
        width: 100%;
    }
    label{
        font-size: 14px;
    }
    input{
        padding: 7px;
    }
    .btn-container{
        display: grid;
        justify-content: center;
    }
    .btn-container button{
        background: #415B37;
        padding: 10px 45px ;
        border-radius: 20px;
        color: white;
        border: none;

    }
    @media (max-width: 900px){
        .container-form{
            width: 100%;
            padding: 15px;
        }
    }
    
    @media (max-width: 560px){
        .divide-two{
            grid-template-columns: 1fr;
        }
    }
</style>