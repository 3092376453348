<template>
    <div class="container-developer">
        <img class="img-developer" src="../../assets/terrain/banner-developer.jpg" alt="">
       <div class="content-developer">
            <div class="developer">
                <h2>DESARROLLA TU INVERSIÓN</h2>
                <p>Materializamos su proyecto de inversión junto a un equipo de profesionales expertos en diseño, maquetado y ejecución del modelo ideal de su casa.</p>
                <div>
                    <router-link :to="{name:'Projects'}">VER MÁS</router-link>
                </div>
            </div>
           <div class="d-relative">
                <div class="float-developer">
                    <img src="../../assets/terrain/float.jpg" alt="">
                    <p>Nuestro departamento de arquitectura trabajará en su proyecto presentándole modelos, planos, ideas y materiales de su futura casa.</p>
                </div>
           </div>
       </div>
    </div>
</template>
<script>
    export default {
        
    }
</script>
<style scoped>
    .container-developer{
        position: relative;
        height: 400px;
        
        margin: 85px 0 15px 0;
    }
    .img-developer{
        position: absolute;
        width:100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        filter: brightness(0.5);
    }
    .content-developer{
        width: 900px;
        z-index: 2;
        position: absolute;
        display: grid;
        grid-template-columns: 1fr 1fr ;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        height: 100%;
    
    }
    .developer{
        display: grid;
        align-self: center;
        color: white;
        text-align: right;
        padding-right: 35px;
    }
    .developer a{
        background: white;
        border-radius: 15px;
        color: black;
        width: max-content;
        padding: 5px 20px;
        font-size: 12px;
        text-decoration: none;
    }
    .developer a:hover{
        opacity: 0.8;
        cursor:pointer;
    }
    .d-relative{
        position: relative;
    }
    .float-developer{
        margin-top:-35px;
        position: absolute;
        width: 350px;
        height:auto;
        border: none;
        border-radius: 10px;
        background-color: white;
        box-shadow: 2px 2px 10px black;    }
    .float-developer img{
        width: 100%;
        border-radius: 10px 10px 0 0;
    }
    .float-developer p{
        padding: 15px 35px;
        text-align: justify;
    }
    @media (max-width: 900px){
        .d-relative{
            display: none;
        }
        .content-developer{
            grid-template-columns: 1fr;
            width: 100%;
        }
        .container-developer{
            margin: 15px 0;
        }
        .developer{
            text-align: center;
        }
    }
</style>