<template>
   <div>
      <div>
        <slider-component/>
      </div>
      <div class="about">
        <h1>This is an about page</h1>
        <h1>This is an about page</h1>

        <h1>This is an about page</h1>

        <h1>This is an about page</h1>

        <h1>This is an about page</h1>
        <h1>This is an about page</h1>
      </div>
   </div>
</template>
<script>
import SliderComponent from '@/components/SliderComponent.vue'
export default {
    name:'Test',
    components: {
    'slider-component':SliderComponent
  }
}
</script>
<style scoped>


</style>