<template>
    <div>
        <div class="container-projects">
          <!-- <div>
              <img src="../assets/projects/maras.jpg" alt="">
            <div class="content-project">
                  <h3 class="title-project">Maras</h3>
                  <div>
                    <p>Cerca de las Salineras de Maras y con una espectacular vista al nevado.</p>
                    <router-link class="btn-project" :to="{name:'Terrain'}">VER MÁS</router-link> 
                  </div>
            </div>
          </div> -->
          <div>
              <img src="../assets/projects/simataucca.jpg" alt="">
              <div class="content-project">
                  <h3 class="title-project">Simataucca</h3>
                  <div>
                    <p>Rodeado de hermosas vistas, con acceso predilecto al Valle Sagrado y a enigmáticos lugares</p>
                    <router-link class="btn-project" :to="{name:'Terrain'}">VER MÁS</router-link> 
                  </div>
              </div>
          </div>
          <div>
              <img src="../assets/projects/ayarmaca.jpg" alt="">
            <div class="content-project">
              <h3 class="title-project">Ayarmaca</h3>
              <div>
                <p>Disfruta de la naturaleza y una gran vista en nuestros terrenos en Ayarmaca a 30min de la ciudad de Cusco.</p>
                <router-link class="btn-project" :to="{name:'Terrain'}">VER MÁS</router-link> 
              </div>
            </div>
          </div>
          <div>
              <img src="../assets/projects/4.png" alt="">
            <div class="content-project">
              <h3 class="title-project">Pillcopata</h3>
              <div>
                <p>Explorar, navegar y disfrutar al máximo experiencias entre el verdor y las fuertes aguas de la Selva </p>
                <router-link class="btn-project" :to="{name:'Terrain'}">VER MÁS</router-link> 
              </div>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
    export default {
        name:'ProjectListV1'
    }
</script>

<style scoped>

  .container-projects{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 15px;
    padding: 55px;
    margin-bottom: 25px;
  }
  .container-projects>div{
    position: relative;
    display: block;
    transition: all 0.5s;
  }
  .container-projects img{
    width: 100%;
    height: 550px;
    object-fit: cover;
    filter: brightness(0.5);
    border-radius: 15px;
    
  }
  .content-project{
    width: 100%;
    padding: 65px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 9999;
    color: white;
    text-align: center;
    transition: all 0.5s;
  }
  .title-project{
    text-transform: uppercase;
    transition: all 0.3s;
    font-size: 25px;
  }
  .content-project>div{
    opacity: 0;
    transition: all 0.3s;
    height: 0;
  }
  .container-projects>div:hover img{
    filter: brightness(0.8);
    transition: all 0.3s;
    /*scale: 1.03;*/
    -webkit-transform: scale( 1.03);
    -moz-transform: scale( 1.03);
   -o-transform: scale( 1.03);
    transform: scale( 1.03);
    box-shadow: 2px 2px 10px rgb(66, 66, 66);
  }
  .container-projects>div:hover .title-project{
    transition: all 0.3s;
    text-shadow: 2px 2px 3px rgb(78, 78, 78);
  }
  .container-projects>div:hover .content-project>div{
    height: auto;
    opacity: 1;

    
  }
  .btn-project{
    border: none;
    background: white;
    color: black;
    padding: 5px 15px;
    border-radius: 15px;
    text-decoration: none;
    font-size: 15px;
  }
  .btn-project:hover{
    background: #415B37;
    color: white;
  }
  @media (max-width:1200px) {
    .container-projects{
      grid-template-columns: repeat(3,1fr);
    }
  }
@media (max-width:900px) {
 .container-projects{
    grid-template-columns: repeat(2,1fr);
    gap: 15px;
    padding: 35px;
    margin-bottom: 20px;
  }
  .content-project{
    padding: 10px;
  }
   .container-projects img{
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
  .title-project{
    font-size: 20px;
  }
}
@media (max-width:500px) {
 .container-projects{
    grid-template-columns: repeat(1,1fr);
    gap: 15px;
    padding: 35px;
    margin-bottom: 20px;
  }
  .title-project{
    font-size: 20px;
  }
}
</style>