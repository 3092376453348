<template>
   <div>
        <div class="slider-fertil">
            <swiper :pagination="true" navigation autoplay :loop="true" class="mySwiper">
                <swiper-slide>
                    <img src="../assets/slider-simataucca.jpg" alt="slider">
                    <div class="content-slider">
                        <h2>SIMATAUCCA</h2>
                        <p>A 30 minutos de cusco</p>
                        <div>
                            <router-link :to="{name:'Terrain'}" class="btn-slider">
                            VER MÁS
                        </router-link>
                        </div>
                    </div>
                </swiper-slide>
                <!-- <swiper-slide>
                    <img src="../assets/slider-maras.jpg" alt="slider">
                    <div class="content-slider">
                        <h2>MARAS</h2>
                        <p>A 30 minutos de cusco</p>
                        <router-link :to="{name:'Terrain'}" class="btn-slider">
                            VER MÁS
                        </router-link>
                    </div>
                </swiper-slide> -->
                <swiper-slide>
                    <img src="../assets/slider-ayarmaca.jpg" alt="slider">
                    <div class="content-slider">
                        <h2>AYARMARCA</h2>
                        <p>A 30 minutos de cusco</p>
                        <router-link :to="{name:'Terrain'}" class="btn-slider">
                            VER MÁS
                        </router-link>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
   </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/swiper.min.css'
    import 'swiper/swiper-bundle.css';
    import 'swiper/components/pagination/pagination.min.css';

    import SwiperCore, {
    Pagination,Navigation,Autoplay
    } from 'swiper';

    SwiperCore.use([Pagination,Navigation,Autoplay]);

    export default {
        name: 'SliderComponent',
        components: {
            Swiper,
            SwiperSlide,
        },
       
    }
</script>

<style scoped>
.content-slider{
    position: absolute;
    padding: 25px;
    width: 400px;
    display: grid;
    justify-content: left;
    background-color: transparent;
    text-align: start;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.content-slider h2{
    font-size: 45px;
    text-shadow: 2px 2px 3px #4a4a4a;
    margin: 2px;
}
.content-slider p{
    text-shadow: 2px 2px 3px #4a4a4a;
}
.content-slider a{
    color: black;
    padding: 5px 15px;
    background-color: white;
    border-radius: 15px;
    width: max-content;
    border:none;
    font-size: 15px;
    text-decoration: none;
}
.content-slider a:hover{
    background-color: rgb(202, 202, 202);
}
.slider-fertil{
    width: 100%;
    height: 650px;
}
.swiper {
  width: 100%;
  height: 100%;
}
.mySwiper{
    height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 900px){
.content-slider h2{
    font-size: 25px;
    }
}
.content-slider{
    padding-left: 70px;
}
</style>