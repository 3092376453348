<template>
    <div class="container-offer">
        <div class="grid-we-offer">
            <div class="content-offer content-offer1">
                <div>
                    <h3 class="title-offer">Brindamos <br/> Respaldo</h3>
                </div>
                <div>
                    <p>Contamos con un equipo de especialistas, abogados, contadores, administradores, arquitectos, ingenieros y vendedores para brindar un sólido respaldo en nuestra propuesta para que adquieran el terreno ideal que han estado buscando.</p>
                </div>
            </div>
            <div class="content-img-offer">
                <img class="img-offer img-offer-1" alt="Vue logo" src="../assets/r5.jpg">
            </div>
        </div>
        <div class="grid-we-offer">
            <div class="content-offer content-offer2 order-2">
                <h3 class="title-offer">Ofrecemos <br/> Acompañamiento</h3>
                <p>
                    Le acompañamos no sólo en hacer la compra de un terreno, si no también ofreciendo la oportunidad de desarrollar su inversión hasta el punto de ver su casa / bungalós / cabaña hecha realidad y edificada directamente con nosotros.
                </p>
            </div>
            <div class="content-img-offer">
                <img class="img-offer img-offer-2" alt="Vue logo" src="../assets/r6.jpg">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"WeOfferComponent"
}
</script>
<style scoped>
    .container-offer{
        max-width: 800px;
        margin: 80px auto 80px auto;
    }
    .grid-we-offer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* height: 330px; */
    }
    .grid-we-offer div{
        position: relative;
    }
    .title-offer{
        font-size: 35px;
        font-weight: 700;
        margin: 0;
    }
    .img-offer{
        /* position: absolute; */
        height: 350px;
        width: 400px;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: 2px 2px 10px rgb(126, 126, 126);
    }
    .img-offer-1{
        left: -15px;
        bottom: -15px;
    }
    .img-offer-2{
        right: -15px;
        top: -15px;
    }
    .content-offer{
        display: grid;
        justify-content: center;
        align-items: center;
        align-self: center;
    }
    .content-offer1{
        padding:15px 50px 15px 15px;
        padding-bottom: 15px;
        text-align: right;
    }
    .content-offer2{
        padding:15px 15px 15px 50px;
        padding-top: 15px;
        text-align: left;
    }
    .order-2{
        order: 2;
    }
    @media (max-width: 900px) {
        .grid-we-offer{
            height: auto;
            grid-template-columns: repeat(1, 1fr);
        }
        .img-offer{
            position: relative;
            width: 100%;

        }
        .img-offer-1,.img-offer-2{
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;

        }
        .content-img-offer{
            padding:0 7px;
        }
        .order-2{
            order: initial;
        }
        .container-offer{
            margin:10px auto 50px auto;
        }
        .content-offer2{
            text-align: inherit;
            padding:5px 5px 5px 15px;
        }
         .content-offer1{
             text-align: inherit;
            padding:5px 5px 5px 15px;
        }
        .title-offer{
            font-size: 25px;
            font-weight: 600;
            margin:1rem 0 1rem 0;
        }
    }
</style>