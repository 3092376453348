<template>
    <div>
        <div class="container-carousel">
            <transition-group name="flip-list" tag="div" class="container-sliders" appear>
                <div v-for="(item,index) in gallery" :key="item.id" class="item-terrain-carousel">
                    <div class="content-slider" @click="change_index(index)" :class="index === 1 ?'active-terrain-carousel':''">   
                        <img :src="getImgUrl(item.img_name)" :alt="item.img_name">
                        <div class="content-slider-hover">
                            <a @click="change_toggle">
                                <font-awesome-icon class="icon-slider" :icon="['far', 'images']" />
                            </a>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>

        <div v-show="toggle" class="container-bg-fixed">
            <div @click="change_toggle" class="btn-fixed">
                X
            </div>
            <transition-group name="flip-list" tag="div" class="container-sliders-fixed" appear>
                <div v-for="(item,index) in gallery" :key="item.id" class="" :class="index === 1?'item-terrain-carousel-fixed':'d-none'" >
                    <div @click="change_index(index)" :class="index === 1 ?'':'d-none'">   
                        <img :src="getImgUrl(item.img_name)" :alt="item.img_name">
                    </div>
                </div>
            </transition-group>
            <transition-group name="flip-list" tag="div" class="container-sliders-small" appear>
                <div v-for="(item,index) in gallery" :key="item.id" class="item-terrain-carousel-small">
                    <div @click="change_index(index)" :class="index === 1 ?'active-terrain-carousel':'op-5'">   
                        <img :src="getImgUrl(item.img_name)" :alt="item.img_name">
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
    import {onMounted, ref, toRefs} from 'vue'

    export default {
         props: {
            gallery: Array,
            default: function () {
                return []
            }
        },
        setup(props){
            const toggle = ref(false);
            var sliders = toRefs(props).gallery

            function change_toggle(){
                toggle.value = !toggle.value;
            }

            function change_index(index) {
               index
               if (index == 0){
                   let ultimo = sliders.value.shift();
                   sliders.value.splice(1,0,ultimo)
               }
               else{
                    var limit = index-2;
                    for (var i=0;i<=limit;i++) {
                    (function(ind) {
                        setTimeout(function(){
                            let ultimo = sliders.value.shift();
                            //console.log(ultimo)
                            sliders.value.push(ultimo)
                            console.log(ind);
                            if(ind === limit){
                                console.log('It was the last one');
                            }
                        }, 50 + (400 * ind));
                    })(i);
                    
                    }
               }
           }
            onMounted(() => {
                sliders.value = props.gallery
            })
           function getImgUrl(pet) {
                var images = require.context('../../assets/projects/', false, /\.jpg$/)
                return images('./' + pet + ".jpg")
            }
            return{
                sliders,
                change_index,
                getImgUrl,
                toggle,
                change_toggle,

            }
        }
    }
</script>

<style scoped>
.icon-slider{
    font-size: 25px;
}
.icon-slider:hover{
    cursor: pointer;
}
.content-slider{
    position: relative;
}
.content-slider-hover{
    opacity: 0;
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: 0.5s all ease-in;
    color: white;
    display: grid;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.content-slider-hover:hover{
    opacity: 1;
    background: rgba(0, 0, 0, 0.678);
}
.btn-fixed{
    color: white;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 30px;
    font-weight: bold;
}
.btn-fixed:hover{
    cursor: pointer;
    opacity: 0.9;
}
.op-5 img{
    filter: brightness(0.3);
}
.d-none{
    display: none;
}
.container-bg-fixed{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.88);
    z-index: 9999999;
    display: grid;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease-out;
}
.flip-list-move {
    opacity: 0.2;
    transition: transform 0.5s ease-out;
}
.container-carousel{
    margin-top: -150px;
    display: grid;
    justify-content: center;
}    
.container-sliders-small{
    width: 400px;
    display: grid;
    grid-template-columns: repeat(3,100px);
    gap: 80px;
    margin: auto;
}
.container-sliders-fixed{
    width: 980px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 80px;
    padding: 38px;
}
.container-sliders{
    width: 900px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 50px;
}
.active-terrain-carousel{
    /*scale: 1.2;*/
    -webkit-transform: scale( 1.2);
    -moz-transform: scale( 1.2);
    -o-transform: scale( 1.2);
    transform: scale( 1.2);
}
.container-sliders img{
    z-index: 9999;
    width: 100%;
    border-radius: 15px;
}
.item-terrain-carousel-small{
    border-radius: 15px;
    z-index: 9999;
}
.item-terrain-carousel-small img{
    width: 100%;
    height: auto;
    object-fit: cover;
}
.item-terrain-carousel{
    border-radius: 15px;
    z-index: 9999;
}
.item-terrain-carousel img{
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.item-terrain-carousel-fixed img{
    width: 100%;
    height: auto;
    object-fit: cover;
}
@media (max-width:980px) {
    .container-carousel{
        margin-top: -75px;
    }
    .container-sliders{
        width: 100%;
    }
    .container-sliders-fixed{
        width: 100%;
    }
}
@media (max-width:650px) {
    .container-carousel{
        margin-top: -10px;
    }
    .container-sliders{
        gap: 25px;
        grid-template-columns: repeat(1,1fr);
    }
}
</style>