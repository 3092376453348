<template>
    <div style="display:none" class="members-container">
        
        <div class="div-member">
            <img class="icon-banner" src="../assets/members/patricia.png" alt="">
            <h4>PATRICIA ESPINOZA</h4>
            <a href="tel:+51986024910">+51 986 024 910</a>
        </div>
        <div class="div-member">
            <img class="icon-banner" src="../assets/members/daniel.png" alt="">
            <h4>DANIEL MARROQUÍN</h4>
            <a href="tel:+51964073144">+51 964 073 144</a>
        </div>
        <div class="div-member">
            <img class="icon-banner" src="../assets/members/grecia.jpg" alt="">
            <h4>GRECIA UCAÑAY</h4>
            <a href="tel:+51975147181">+51 975 147 181</a>
        </div>
        
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
    .members-container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        background-color: transparent;
        margin: 45px 200px;
        text-align: center;
    }
    .div-member img{        
        border-radius: 50%;
        width: 150px;
        height: 150px;
        object-fit: cover;
    }
    .div-member a{
        color: #415B37;
    }
    .div-member a:hover{
        color: #2a3a24;
        font-weight: 500;
    }
    @media (max-width: 1100px){
         .members-container{
            grid-template-columns: repeat(3, 1fr);
            gap: 15px;
            margin: 45px 50px!important;
         }
     }
      @media (max-width: 900px){
         .members-container{
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
            margin: 45px 50px!important;
         }
     }
</style>