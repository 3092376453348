<template>
    <div>
        <div>
            <img class="img-banner-title" alt="Vue logo" src="../assets/slider-simataucca.jpg">
        </div>
        <h2 class="decorated"><span>TERRENOS</span></h2>
        <div class="container-title-projects-carousel">
            <terrain-component/>
        </div>
    </div>
</template>

<script>
import TerrainComponent from '../components/terrain/TerrainComponent.vue'
    export default {
        name:'Projects',
        components:{
            TerrainComponent
        }
    }
</script>

<style scoped>
    .img-banner-title{
        height: 450px;
        object-fit: cover;
        width: 100%;
        object-position: bottom;
    }
    .title-projects {
    width: 70%;
    margin: .7em auto;
    overflow: hidden;
    text-align: center;
    font-weight:500;
    color: rgb(0, 0, 0);
    font-size: 35px;
    }
    .title-projects:before, .title-projects:after {
    content: "";
    display: inline-block;
    width: 50%;
    margin: 0 .5em 0 -55%;
    vertical-align: middle;
    border-bottom: 1px solid;
    }
    .title-projects:after {
    margin: 0 -55% 0 .5em;
    }
      .decorated{
        overflow: hidden;
        text-align: center;
        color: #415B37;
    }
    .decorated > span{
        position: relative;
        display: inline-block;
    }
    .decorated > span:before, .decorated > span:after{
        content: '';
        position: absolute;
        top: 50%;
        border-bottom: 2px solid;
        width: 591px;
        margin: 0 20px;
    }
    .decorated > span:before{
        right: 100%;
    }
    .decorated > span:after{
        left: 100%;
    }
</style>