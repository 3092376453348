<template>
    <div class="container-schedule">
        <img v-if="bg" class="img-schedule" src="../assets/bg.jpg" alt="">
        <div class="content-schedule">
            <div>
                <h3 :class="bg?'white':'dark'" class="title-schedule">{{ title }}</h3>
                <p style="margin-bottom:2rem" :class="bg?'white':'black'">{{ description }}</p>
                <div>
                    <a target="_blank" :href="link" :class="bg?'dark bg-white':'white bg-dark'" class="btn-schedule" :to="link">{{btn_name}}</a>
                </div>
            </div>
        </div>
        </div>
</template>
<script>
    export default {
        name:'ScheduleComponent',
        props:['title','description','btn_name','link','bg']
    }
</script>

<style scoped>
    .img-schedule{
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        filter: brightness(0.5);
        object-fit: cover;
    }
    .container-schedule{
        position: relative;
        display: grid;
        justify-content: center;
        
        height: 350px;
        color: white;
        text-align: center;
    }
    .content-schedule{
        display: grid;
        align-items: center;
        width: 700px;
        z-index: 1;
    }
    .content-schedule>div{
        display: grid;
        align-items: center;
    }
    .content-schedule h3{
        margin-top: 0;
        margin-bottom: 10px;
    }
    .dark{
        color: #415b37;
    }
    .black{
        color: black;
    }
    .white{
        color:white;
    }
    .bg-dark{
        background-color: #415b37 ;
    }
    .bg-white{
        background-color: white;
    }
    .btn-schedule{
        padding: 10px 23px;
        border: none;
        border-radius: 18px;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .btn-schedule:hover{
        background-color: rgb(67, 155, 67);
        color: white;
    }
    .title-schedule{
        font-size: 35px;
        text-transform: uppercase;
    }
    @media (max-width: 900px){
        .content-schedule{
            width: 100%;
            display: grid;
            align-items: center;
        }
        .content-schedule > div{
            padding: 20px;
        }
        .title-schedule{
            font-size: 25px;
        }
    }
</style>