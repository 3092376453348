<template>
   <div>
      <div>
        <slider-component/>
        <banner-home-component/>
        <we-offer-component data-aos="fade-up" />
        <banner-offer-component/>
        <title-component data-aos="fade-up"/>

        <ProjectsListV1Component/>
        <!-- <projects-list-component data-aos="fade-up"/> -->

        <investment-component data-aos="fade-up"/>
        <!-- <financingbox-component/> -->
        <manager-component data-aos="fade-up"/>
        <members-component data-aos="fade-up"/>
        <coment-component data-aos="fade-up"/>
        <schedule-component
        title="Agendemos una reunión"
        description="Nuestros asesores de venta se encuentran disponibles para atender tus requerimientos en el momento que lo necesites. Contáctanos y agenda una visita, ¡Es el primer paso para cumplir tus sueños!"
        btn_name="Contactar"
        link="https://wa.link/zv63qy"
        :bg="true"
        />
        <!-- link="https://wa.link/d36hfj"   btn_name="Contactar por whatsapp"-->
      </div>
   </div>
</template>
<script>
import SliderComponent from '@/components/SliderComponent.vue'
import BannerHomeComponent from '../components/BannerHomeComponent.vue';
import WeOfferComponent from '../components/WeOfferComponent.vue'
import BannerOfferComponent from '../components/BannerOfferComponent.vue'
import TitleComponent from '../components/TitleComponent.vue'

//import ProjectsListComponent from  '../components/ProjectsListComponent.vue'
import ProjectsListV1Component from  '../components/ProjectsListV1Component.vue'

import InvestmentComponent from '../components/InvestmentComponent.vue'
//import FinancingboxComponent from '../components/FinancingboxComponent.vue'
import ManagerComponent from '../components/ManagerComponent.vue'
import MembersComponent from '../components/MembersComponent.vue'
import ComentComponent from '../components/ComentComponent.vue'
import ScheduleComponent from '../components/ScheduleComponent.vue'




export default {
    name:'Started',
    components: {
    'slider-component':SliderComponent,
    'we-offer-component':WeOfferComponent,
    'banner-offer-component':BannerOfferComponent,
    TitleComponent,
    // ProjectsListComponent,
    InvestmentComponent,
    //FinancingboxComponent,
    ManagerComponent,
    MembersComponent,
    ComentComponent,
    ScheduleComponent,
    BannerHomeComponent,
    ProjectsListV1Component,

    
  }
}
</script>
<style scoped>


</style>