<template>
    <div>
        <div class="container-fixed">
            <transition name="fade">
                <div v-show="toggle_fixed" class="content-fixed">
                    <div class="header-fixed">
                        <small>Estamos para ayudarte</small>
                    </div>
                    <div>
                        <div class="memberfixed">
                            <img class="icon-banner" src="../../assets/members/fabricio.jpg" alt="">
                            <div class="details-fixed">
                                <h5 class="m-0">FABRICIO DURAND</h5>
                                <a href="https://wa.link/zv63qy" class="m-0">+51 933 787 678</a>
                            </div>
                        </div>
                        <div class="memberfixed" style="display:none">
                            <img class="icon-banner" src="../../assets/members/daniel.png" alt="">
                            <div class="details-fixed">
                                <h5 class="m-0">DANIEL MARROQUÍN</h5>
                                <a href="https://api.whatsapp.com/send?phone=51964073144&text=¿Deseo%20mayor%20información?" class="m-0">+51 964 073 144</a>
                            </div>
                        </div>
                        <div class="memberfixed" style="display:none">
                            <img class="icon-banner" src="../../assets/members/grecia.jpg" alt="">
                            <div class="details-fixed">
                                <h5 class="m-0">GRECIA UCAÑAY</h5>
                                <a href="https://api.whatsapp.com/send?phone=51975147181&text=¿Deseo%20mayor%20información?" class="m-0 link">+51 975 147 181</a>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
              </transition>
            <div @click="change_toggle_fixed" class="btn-fixed">
                <font-awesome-icon class="icon-contact-fixed" :icon="['fab', 'whatsapp']" />
            </div>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue'

    export default {
        setup(){
            const toggle_fixed = ref(false);
            function change_toggle_fixed(){
                toggle_fixed.value = !toggle_fixed.value;
            }

            return{
                toggle_fixed,
                change_toggle_fixed
            }
        }
    }
</script>

<style scoped>
    .m-0{
        margin: 0;
    }
    .memberfixed{
       display: grid;
        grid-template-columns: 65px 1fr;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 15px;
    }
    .memberfixed img{
        width: 50px;
        border-radius: 50%;
    }
    .memberfixed a{
        color: #199F4B;
    }
    .memberfixed a:hover{
        color: #018a33;
        font-weight: 500;
    }
    .details-fixed{
        text-align: left;
        display: grid;
        align-items: center;
    }
    .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    }
    .content-fixed{
        position: absolute;
        bottom: 121%;
        right: 0%;
        background: rgb(255, 255, 255);
        width: 250px;
        border-radius: 15px;
        text-align: center;
        box-shadow: 2px 2px 10px rgb(126, 126, 126);
    }
    .header-fixed{
        background: #199F4B;
        color: white;
        margin: 0;
        padding: 15px;
        border-radius: 15px 15px 0 0;
    }
    .btn-fixed{
        background: #25D366;
        box-shadow: 2px 2px 5px rgb(95, 95, 95);
        padding: 11px 12px 11px 12px;
        border-radius: 50%;
       
    }
    .icon-contact-fixed{
        font-size: 30px;
        margin: 0;
        display: block;
        color: #fff;
    }
    .container-fixed{

        position: fixed;
        bottom: 25px;
        right: 25px;
        z-index: 99999999;
    }
</style>