<template>
   <div>
       <div class="container-content-terrain">
        <div class="content-terrain">
            <div>
                <!-- <span>-{{terrain}}-</span> -->
                <h2>Área Total</h2>
                <p class="color-p">{{ terrain.area }}</p>
                <h2>Ubicación</h2>
                <p class="color-p">{{ terrain.location }}</p>
                <h2>Descripción</h2>
                <p class="color-p"> {{ terrain.description }}
                </p>
                <h2>Financiamiento</h2>
                <p class="color-p"> {{ terrain.financy}}</p>
            </div>
            <div>
                <iframe :src="terrain.maps" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                <!-- <vue-picture-swipe :items="[
                {src: 'http://localhost:8080/img/slider-simataucca.f5853f6a.jpg',thumbnail: 'http://example.org/sm1.jpg',w: 600,h: 400, title: 'Will be used for caption'},
                {src: 'http://localhost:8080/img/slider-simataucca.f5853f6a.jpg',thumbnail: 'http://example.org/sm2.jpg',w: 1200,h: 900}
            ]"></vue-picture-swipe> -->

            </div>
        </div>
        <div class="container-g">
            <h2>Con todo lo que necesitas</h2>
            <div class="services-container">
                <div class="service" v-for="(service,index) in terrain.services" :key="index">
                    <img class="img-banner-title" alt="Vue logo" :src="getImgUrl(service.icon)">
                    <div>
                        <span class="text-uppercase">{{ service.subtitle }}</span>
                        <h3 class="text-uppercase">{{ service.title }}</h3>
                    </div>
                </div>
                <!-- <div class="service">
                    <img class="img-banner-title" alt="Vue logo" src="../../assets/icon-services/basic.png">
                    <div>
                        <span>acceso a</span>
                        <h3>SERVICIOS BÁSICOS</h3>
                    </div>
                </div>
                <div class="service">
                    <img class="img-banner-title" alt="Vue logo" src="../../assets/icon-services/car.png">
                    <div>
                        <span>acceso</span>
                        <h3>VEHICULAR</h3>
                    </div>
                </div>
                <div class="service">
                    <img class="img-banner-title" alt="Vue logo" src="../../assets/icon-services/layout.png">
                    <div>
                        <span>lotes proyectados</span>
                        <h3>TRES</h3>
                    </div>
                </div> -->
            </div>
        </div>
   </div>
   </div>
</template>

<script>
    export default {
       props:['terrain'],
       setup(){
           function getImgUrl(pet) {
                var images = require.context('../../assets/icon-services/', false, /\.png$/)
                return images('./' + pet + ".png")
            }
            return{
                getImgUrl
            }
       }

    }
</script>

<style scoped>
    .color-p{
        color: #464646;
    }
    .text-uppercase{
        text-transform: uppercase;
    }
    .container-content-terrain{
        display: grid;
        justify-content: center;
    }
    .content-terrain{
        width: 1000px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
    .content-terrain h2{
        font-size: 25px;
    }
    .content-terrain h2{
        margin: 0;
    }
    .container-g{
         width: 1000px;
    }
    .services-container{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 1.5rem;
    }
    .service{
        display: grid;
        grid-template-columns:0.2fr 1fr;
        gap: 15px;
        align-content: center;
        align-items: center;
    }
    .service img{
        width: 100%;
    }
    .service span{
        font-size: 12px;
        color: rgb(73, 73, 73);
        text-transform: uppercase;
    }
    .service h3{
        margin: 0;
        font-size: 15px;
    }
      @media (max-width: 900px){
          .service{
              grid-template-columns: 1fr 202px;
          }
          .container-g{
              width: 100%;
          }
         .container-content-terrain{
            display: block;
        }
        .content-terrain{
            grid-template-columns: 1fr;
            width: 100%;
            padding: 10px;
        }
        .services-container{
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
        }
        .service img{
        width: 40px;
        }
    }
    @media (max-width: 530px){
        .services-container{
            grid-template-columns: repeat(1, 1fr);
        }
        .service{
            margin: 15px 0;
        }
    }
</style>