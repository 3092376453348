<template>
    <div>
       <div class="container-dismus">
           <!--<button @click="shuffle_1">Shuffle</button>-->
             <div class="container-center">
                 <div class="container-carousel">
                    <transition-group name="flip-list" tag="div" class="container-terrain-carousel" appear>
                    <div v-for="(item,index) in sliders" :key="item.id" class="item-terrain-carousel" :class="item.id === terrain.id ?'active-terrain-carousel':'dark'">
                        <div @click="change_index(index),send_terrain(item),sendScroll('content_terrain')">
                            <h3 class="z-index-9">{{ item.title }}</h3>
                            <img :src="getImgUrl(item.img_name)" :alt="item.img_name">
                        </div>
                    </div>
                    </transition-group>
                 </div>
             </div>
            <div>
                <div id="content_terrain">
                    <h2 class="text-center">{{terrain.title}}</h2>
                    <terrain-content-component :terrain="terrain" /> 
                    <br>
                    <br> 
                    <br>
                </div>
            </div>
            <developer-component/>
             <schedule-component
                title="Agendemos una reunión"
                description="Nuestros asesores de venta se encuentran disponibles para atender tus requerimientos en el momento que lo necesites. Contáctanos y agenda una visita, ¡Es el primer paso para cumplir tus sueños!"
                btn_name="Contactar"
                link="https://wa.link/zv63qy"
                :bg="false"
                />
                <!--btn_name="Contactar por whatsapp"
                link="https://wa.link/d36hfj"-->
                <!-- https://wa.link/zv63qy //fabricio-->
                <!-- mailto:ventas@tierrafertil.pe -->
       </div>
    </div>
</template>

<script>
    import { ref,onMounted  } from 'vue';
    import TerrainContentComponent from './TerrainContentComponent.vue'
    import DeveloperComponent from './DeveloperComponent.vue'
    import ScheduleComponent from '../ScheduleComponent.vue'

    export default {
        components:{
            TerrainContentComponent,
            DeveloperComponent,
            ScheduleComponent,
        },
        setup(){
           const terrain = ref({});
           const sliders = ref([
            //    {
            //         id:1,
            //         title:'Maras',
            //         img_name:'slider-maras',
            //         maps:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d87847.49596446515!2d-72.15091273967587!3d-13.328626364823613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x916dc3429570fb93%3A0xf01dbd5dc9551c44!2sMaras%2008656!5e0!3m2!1ses-419!2spe!4v1634246552855!5m2!1ses-419!2spe',
            //         area:'5 hectáreas distribuidas en 41 lotes proyectados.',
            //         location:'Nuestros proyectos están ubicados a 30 minutos del centro de Cusco, tomando la ruta Cusco - Urubamba, en las comunidades de Simataucca y Ayarmaca, y a sólo minutos de distancia de centros poblados (Simataucca, Ayarmaca, Cachimayo) y con acceso vehicular a todos los proyectos.',
            //         description:'Bosques, bellísimas vistas a nevados y montañas con accesos a ríos, lagunas y centros arqueológicos, todo esto y mucho más podrán encontrar nuestros clientes gracias a los proyectos que ofrecemos.',
            //         financy:'Financiamientos con Caja Cusco hasta 24 meses para pagarlo, sin cuota inicial.',
            //         services:[
            //             {
            //                 icon:'view',
            //                 subtitle:'Vista a las montañas',
            //                 title:'y nevados'
            //             },
            //             {
            //                 icon:'basic',
            //                 subtitle:'Acceso a',
            //                 title:'SERVICIOS BÁSICOS'
            //             },
            //             {
            //                 icon:'car',
            //                 subtitle:'Acceso',
            //                 title:'VEHICULAR'
            //             },
            //             {
            //                 icon:'layout',
            //                 subtitle:'lotes proyectados',
            //                 title:'41'
            //             }
            //         ]
            //     },
                {
                    id:2,
                    title:'Simataucca',
                    img_name:'slider',
                    maps:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9777.764799041704!2d-72.06081934619968!3d-13.455055770550596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x916dd1a2ccbf6791%3A0xb4b26400d7f085d0!2sColegio%20de%20simataucca!5e0!3m2!1ses-419!2spe!4v1637357903162!5m2!1ses-419!2spe',
                    area:'Último lote disponible de 800M2',
                    location:'Ubicados a 30 minutos del centro de Cusco, tomando la ruta Cusco - Urubamba, en las comunidades de Simataucca, y a sólo minutos de distancia de centros poblados (Simataucca, Ayarmaca, Cachimayo) y con acceso vehicular a todos los proyectos.',
                    description:'Bosques, bellísimas vistas a nevados y montañas con accesos a ríos, lagunas y centros arqueológicos, lugares mágicos para reconectar con la naturaleza y vida que nos rodea.',
                    financy:'Contamos con todas las facilidades para que hagan realidad sus sueños de tener un terreno propio, tenemos financiamiento directo o financiamiento con Caja Cusco, hasta 60 meses para pagar el terreno.',
                    services:[
                        {
                            icon:'view',
                            subtitle:'Vista a las montañas',
                            title:'y bosques'
                        },
                        {
                            icon:'basic',
                            subtitle:'acceso a',
                            title:'SERVICIOS BÁSICOS'
                        },
                        {
                            icon:'car',
                            subtitle:'acceso',
                            title:'VEHICULAR'
                        },
                        {
                            icon:'layout',
                            subtitle:'lotes proyectados',
                            title:'TRES'
                        }
                    ]
                },
                {
                    id:3,
                    title:'Ayarmarca',
                    img_name:'slider-ayarmaca',
                    maps:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15974.359009451186!2d-72.11421173023558!3d-13.480247429865232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x916dd1d322cbce1b%3A0x39b5bbd4c1a1d9b7!2sSALON%20COMUNAL%20DE%20LA%20C.C.%20AYARMACA!5e0!3m2!1ses-419!2spe!4v1634246400529!5m2!1ses-419!2spe',
                    area:'LOTES DISPONIBLES 360M2, 420M2, 2500M2.',
                    location:'Ubicados a 30 minutos del centro de Cusco, tomando la ruta Cusco - Urubamba, en las comunidades de Ayarmaca, y a sólo minutos de distancia de centros poblados (Simataucca, Ayarmaca, Cachimayo) y con acceso vehicular a todos los proyectos.',
                    description:'Bosques, bellísimas vistas a nevados y montañas con accesos a ríos, lagunas y centros arqueológicos, lugares mágicos para reconectar con la naturaleza y vida que nos rodea.',
                    financy:'Contamos con todas las facilidades para que hagan realidad sus sueños de tener un terreno propio, tenemos financiamiento directo o financiamiento con Caja Cusco, hasta 60 meses para pagar el terreno.',
                    services:[
                        {
                            icon:'view',
                            subtitle:'Vista a las montañas',
                            title:'y bosques',
                        },
                        {
                            icon:'basic',
                            subtitle:'acceso a',
                            title:'SERVICIOS BÁSICOS',
                        },
                        {
                            icon:'car',
                            subtitle:'acceso',
                            title:'VEHICULAR',
                        },
                        {
                            icon:'layout',
                            subtitle:'lotes proyectados',
                            title:'TRES',
                        }
                    ]
                },
                {
                    id:4,
                    title:'Pillcopata',
                    img_name:'pillcopata',
                    maps:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15071.270282751224!2d-71.29238862298274!3d-12.993423451414577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zMTLCsDU5JzM3LjAiUyA3McKwMTcnMDEuNiJX!5e1!3m2!1ses-419!2spe!4v1634924290131!5m2!1ses-419!2spe',
                    area:'100hectareas ',
                    location:'Ubicación exacta: Provincia de Paucartambo, distrito de Kosñipata en el sector de Sabaluyoc',
                    description:'Contamos con 100 hectareas de terreno en la selva de Pillcopata, selva vírgen, por nuestro terreno corren 2 rios con agua cristalina, excelente proyecto para conservación / reservá / proyecto turísticos.',
                    financy:'',
                    services:[
                        {
                            icon:'view',
                            subtitle:'Vista a las montañas',
                            title:'con rios',
                        },
                       
                        {
                            icon:'car',
                            subtitle:'Futuro acceso',
                            title:'VEHICULAR',
                        },
                        {
                            icon:'layout',
                            subtitle:'lotes proyectados',
                            title:'100 Hectareas',
                        }
                    ]
                },
           ])
           function change_index(index) {
                index
            //    if (index == 0){
            //        let ultimo = sliders.value.shift();
            //        sliders.value.splice(1,0,ultimo)
            //    }
            //    else{
            //         var limit = index-2;
            //         for (var i=0;i<=limit;i++) {
            //         (function(ind) {
            //             setTimeout(function(){
            //                 let ultimo = sliders.value.shift();
            //                 //console.log(ultimo)
            //                 sliders.value.push(ultimo)
            //                 console.log(ind);
            //                 if(ind === limit){
            //                     console.log('It was the last one');
            //                 }
            //             }, 50 + (400 * ind));
            //         })(i);
                    
            //         }
            //    }
           }
           function getImgUrl(pet) {
                var images = require.context('../../assets/', false, /\.jpg$/)
                return images('./' + pet + ".jpg")
            }
            function send_terrain(data){
                terrain.value = data
            }

            function sendScroll(refName) {
                let element = document.getElementById(refName);
                console.log(element);
                let top = element.offsetTop;
                window.scrollTo({
                    top: top,
                    behavior: 'smooth',
                })
            }

            onMounted(() => {
                terrain.value = sliders.value[1]
            })
            return {
                send_terrain,
               terrain,
               sliders,
               change_index,
               getImgUrl,
               sendScroll
           }
        },
       
    }
</script>

<style scoped>
    .text-center{
        text-align: center;
        margin-bottom: 55px ;
        margin-top:0 ;
        padding-top: 85px;
    }
    .container-center{
        display: grid;
        justify-content: center;
    }
    .container-carousel{
        /* max-width: 920px; */
        /*overflow: hidden;*/
    }
   
    .flip-list-move {
        opacity: 0.2;
        transition: transform 0.5s ease-out;
    }    
    .container-terrain-carousel{
        margin: 75px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 75px;

        text-align: center;
    }
    .container-terrain-carousel>div{
        background-color: cadetblue;
    }
    
    .item-terrain-carousel{
        width: 250px;
        position: relative;
        height: 180px;
        border-radius: 15px;
        background: #415B37;;
    }
    .item-terrain-carousel img{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top:0;
        z-index: 1;
        object-fit: cover;
        border-radius: 15px;
        filter: brightness(0.8);
    }
    .active-terrain-carousel{
        /*scale: 1.2;*/
        -webkit-transform: scale( 1.2);
        -moz-transform: scale( 1.2);
        -o-transform: scale( 1.2);
        transform: scale( 1.2);
        box-shadow: 2px 3px 9px #5e5e5e;
    }
    .item-terrain-carousel>div{
        width: 100%;
        z-index: 2;
        position: absolute;
        color: white;
        display: grid;
        justify-content: center;
        align-items: center;
        align-self: center;
        height: 100%;
        font-size: 20px;
        text-transform: uppercase;
        
    }
    .z-index-9{
        z-index: 9;
    }
    .dark{
        filter: brightness(0.5);
    }
    @media (max-width: 900px){
        .container-terrain-carousel{
            grid-template-columns: repeat(1,1fr);
            gap: 35px;
            margin: 75px 15px;
            justify-content: end;
        }
        .item-terrain-carousel{
            width: 230px;
        }
        .item-terrain-carousel h3{
            font-size: 14px;
        }
    }
</style>