<template>
    <div class="container-footer">
        <img class="img-footer" alt="Vue logo" src="../../assets/simbolo.svg">
        <div class="content-footer">
            <div class="icons-container-footer">
                <a target="_blank" href="https://www.facebook.com/tierrafertil.pe"><font-awesome-icon :icon="['fab', 'facebook']"/></a>
                <a target="_blank" href="https://www.instagram.com/tierrafertil.pe/"><font-awesome-icon :icon="['fab', 'instagram']"/></a>
                <!-- <font-awesome-icon :icon="['fab', 'youtube']"/> -->
            </div>
            <p>Recibe nuestra información por e/mail</p>
            <div class="container-input">
                <input :class="false_input.data?'error-input':''" v-model="email" placeholder="email" type="text">
                <button  @click="send_email" class="btn-footer-input">
                    <font-awesome-icon :icon="['fas', 'angle-right']"/>
                </button>
                <small class="error" v-if="false_input.data">
                    ingrese un correo valido por favor
                </small>
            </div>
        </div>
        <loading-component :enviado="loading"></loading-component>
    </div>
</template>

<script>
import {ref} from '@vue/reactivity'
import axios from 'axios'
import LoadingComponent from '../../components/layout/LoadingComponent.vue'

    export default {
        components:{
            LoadingComponent
        },
        setup(){
            const email = ref('');
            const loading = ref({data:false,success:false});//cargar la pantalla de cargar
            const false_input = ref({data:false});

            function send_email(){
                if (!email.value.includes('@')){
                    console.log(email.value)
                    false_input.value.data = true
                }
                else{
                    false_input.value.data = false
                    loading.value.data = true;
                    const headers = {
                        'Authorization': 'Bearer my-token',
                        'My-Custom-Header': 'foobar'
                    };
                    axios.post('https://api.tierrafertil.pe/api/register-email',{
                        email:email.value,
                    },{headers})
                    .then(response => {
                        console.log(response+'listo')
                        loading.value.data = false;
                        loading.value.success = true;
                        setTimeout(function () { loading.value.success = false; }.bind(this), 3000)
                        }
                    )
                    .catch(error => {
                        `Error: ${error.message}`;
                        console.error('There was an error!', error);
                    });
                }
                
            }
            return{
                send_email,
                loading,
                email,
                false_input,
            }
        }
    }
</script>

<style scoped>
    .error-input{
        color: rgb(255, 194, 194)!important;
        background-color: #535353!important ;
    }
    .error{
        color: rgb(255, 205, 205);
    }
    .img-footer{
        position: absolute;
        left: -155px;
        z-index: 1;
        height: 450px;
    }
    .container-footer{
         position: absolute; 
        background-color: #415B37;
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;
        height: 250px;
        color: white;
        overflow: hidden;
    }
    .content-footer{
        width: 700px;
        z-index: 1;
        position: absolute;
          left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
    }
    .container-input{
        height: 35px;
        
        
    }
    .container-input input{
        height: 100%;
        width: 90%;
        border: 1px solid white;
        background-color: transparent;
        padding: 10px;
        font-weight: bold;
        color: white;
    }
    .container-input button{
        padding: 10px;
    }
    .icons-container-footer svg{
        margin: 5px;
        font-size: 35px;
    }
    .icons-container-footer a{
        color: white;
    }
     .icons-container-footer a:hover{
        opacity: 0.8;
    }
    .btn-footer-input{
        border: none;
        height: 100%;
    }
    @media (max-width: 900px){
        .content-footer{
            width: 100%;
            padding: 30px;
        }
    }
</style>