<template>
    <div>
        <!-- <img alt="Projectos Tierra fertil" src="../assets/landing/banner.png" width="100%"> -->
        <div class="p-relative banner-landing">
            <div class="container-banner">
                <div>
                    <p>Obtenga una copia gratuita de nuestra guía exlusiva</p>
                    <h2>9 COSAS QUE DEBES SABER ANTES DE COMPRAR UN TERRENO EN CUSCO</h2>
                    <a @click="sendScroll('form-send')">Obtener copia gratuita</a>
                </div>
                <div>
                    <img class="book" src="../assets/landing/libro.jpg" alt="">
                </div>
            </div>
            <div class="bg-banner-landing">
                <svg id="banner" width="100%" viewBox="0 0 200 100">
                    <polygon id="polygon" points="0,0 0,90 200,80 200,0"
                    fill="#416048"/>
                </svg> 
            </div>
        </div>
        <div class="container-landing-page">
            <div>
                <p>
                    Este informe contiene información vital que lo ayudará a evitar errores comunes y trampas en el proceso de 
                    compra, diseñado específicamente para comprar terrenos en el área de Cusco.
                </p>
                <p>
                    Esta Guía es imprescindible para cualquier persona que busque comprar un terreno en el área de Cusco, para asegurse
                    de tener todos los datos para:
                </p>
                <div class="data-list">
                    <div>
                        <img src="../assets/landing/location.png" alt="" width="150">
                        <p>Elige tu terreno</p>
                    </div>
                    <div>
                        <img src="../assets/landing/money.png" alt="" width="150">
                        <p>Garantice un proceso de compra fluido</p>
                    </div>
                    <div>
                        <img src="../assets/landing/guard.png" alt="" width="150">
                        <p>Proteja su inversión</p>
                    </div>
                </div>
            </div>
        </div>
        <div id="form-send" class="container-form">
            <h2>Obtener copia gratuita</h2>
            <div class="content-form">
                <div>
                    <div class="box-input">
                        <input v-model="name" placeholder="Tu Nombre" id="name" type="text">
                        <small v-if="void_input && name === ''" class="text-red">Este campo esta vacio</small>
                    </div>
                </div>
                <div class="box ">
                    <div class="box-input">
                        <input v-model="number" id="phone" type="text" placeholder="Teléfono / Celular">
                        <small v-if="void_input && number === ''" class="text-red">Este campo esta vacio</small>
                    </div>
                </div>
                <div class="box">
                    <div class="box-input">
                        <input v-model="email" name="email" id="" cols="30" rows="10" placeholder="Dirección de Correo Electrónico">
                        <small v-if="void_input && email === ''" class="text-red">Este campo esta vacio</small>
                    </div>
                </div>
                <div class="btn-container">
                    <button @click="send_data">
                        Consiguelo Ahora
                    </button>
                </div>
                <p v-show="success_message" class="text-success">Tu mensaje se envio con éxito!!!</p>
                <small>No compartimos correos electrónicos con nadie</small>
            </div>
        </div>
       
        <!-- <div>
            {{ loading.data }} --{{ loading.success }}
        </div> -->
        <loading-component :enviado="loading"></loading-component>
    </div>

</template>

<script>
import {ref} from '@vue/reactivity'
import axios from 'axios'
import LoadingComponent from '../components/layout/LoadingComponent.vue'
    export default {
        mounted() {
            let svg = document.getElementById("banner")
            
            let ancho = window.innerWidth;
            console.log(ancho)
            let height_frame = " 130";

            

            if (ancho < 1100){
                height_frame = " 180";
            }


            if (ancho < 900){
                svg.style.display="none";
            }
            let height_left = 110;
            let height_right = 95;
            if (ancho > 1350 && ancho < 1400){
                height_left = 120;
                height_right = 100;
            }
            else if (ancho > 1250 && ancho < 1350){
                height_left = 130;
                height_right = 110;
            }
            else if(ancho < 1100 ){
                height_left = 155;
                height_right = 140;
            }
            else if (ancho > 1100 && ancho < 1350){
                height_left = 130;
                height_right = 120;
            }
            else if (ancho > 1350 && ancho < 1400){
                height_left = 100;
                height_right = 90;
            }
            else if (ancho > 1400 && ancho < 1500){
                height_left = 120;
                height_right = 110;
            }
            else if (ancho > 1500 && ancho < 1600){
                height_left = 110;
                height_right = 100;
            }
            else if (ancho > 1600 && ancho < 1700){
                height_left = 110;
                height_right = 100;
            }
             else if (ancho > 1700 && ancho < 1850){
                height_left = 100;
                height_right = 90;
            }
                else if (ancho > 1850 && ancho < 2200){
                height_left = 85;
                height_right = 70;
            }
            else if (ancho > 2200){
                svg.style.display="none";
            }
            else{
                console.log()
            }
            svg.setAttribute("viewBox", "0 0 200"+height_frame);
           
            document.getElementById("polygon").setAttribute("points", "0,0 0,"+height_left+" 200,"+height_right+" 200,0");
            console.log(svg)
        },
        components:{
            LoadingComponent
        },
        setup(){
            const name = ref('');
            const number = ref('');
            const email = ref('');
            const void_input = ref(false);
            const success_message = ref(false);

            const loading = ref({data:false,success:false});//cargar la pantalla de cargar

            function sendScroll(refName) {
                let element = document.getElementById(refName);
                console.log(element);
                let top = element.offsetTop;
                window.scrollTo({
                    top: top,
                    behavior: 'smooth',
                })
            }

            function send_data(){
                if (name.value === '' || 
                number.value === '' || 
                email === ''
                ){
                    console.log('esta vacios')
                    void_input.value = true;
                }else{
                    loading.value.data = true;
                    const headers = {
                    'Authorization': 'Bearer my-token',
                    'My-Custom-Header': 'foobar'
                    };
                    axios.post('https://api.tierrafertil.pe/api/send-code',{
                        name:name.value,
                        number:number.value,
                        email:email.value
                    },{headers})
                    .then(response => {
                        console.log(response+'listo')
                        loading.value.data = false;
                        loading.value.success = true;
                        success_message.value = true;
                        setTimeout(function () { loading.value.success = false; }.bind(this), 3000)
                        }
                        
                    )
                    .catch(error => {
                        `Error: ${error.message}`;
                        console.error('There was an error!', error);
                    });
                }
                
            }
            function toggle_loading(){
                loading.value = !loading.value
            }
            return{
                sendScroll,
                loading,
                name,
                number,
                email,
                send_data,
                toggle_loading,
                void_input,
                success_message
            }
        }
    }
</script>

<style scoped>
    .d-none{
        display: none;
    }
    .book{
        width: 450px;
    }
    #banner{
        /*border: 1px solid black;*/
    }
    .data-list{
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
    }
    .bg-banner-landing{
        width: 100%;
        height: 100%;
    }
    .banner-landing{
        height: 700px;
    }
    .container-banner{
        z-index: 999;
        position: absolute;     
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
        padding-top: 165px;
    }
    .container-banner > div:nth-child(1){
        padding: 150px;
        color: white;
    }
    .container-banner > div:nth-child(1) small{
        
    }
    .container-banner img{
        box-shadow: -15px 15px 35px rgb(31, 31, 31);
        
    }
    .container-banner > div:nth-child(1) a{
        text-decoration: none;
        background: #c77223;
        padding: 10px;
        color:white;
        border: none;
        border-radius: 5px;
    }
    .container-banner > div:nth-child(1) a:hover{
        background: #9e5b1d;
    }
    .bg-banner-landing{
        position: absolute;
        width: 100%;
        top:0;
        z-index: 1;
    }
    .p-relative{
        position: relative;
    }
    .container-landing-page{
        width: 100%;
        padding: 250px 250px 0 250px;
    }
    
    .w-100{
        width: 100%!important;
    }
   
    .text-success{
        text-align: center;
        font-size: 19px;
        font-weight: 500;
    }
    .text-red{
        color: rgb(255, 158, 158);
    }
    textarea, input{
        font-family: 'Montserrat', sans-serif;
    }
    .container-form{
        width: 700px;
        margin: 50px auto;
        padding: 0px 150px;
        background: #415B37;
        padding: 15px 150px;
        color: white;
        text-align: center;
    }
    .container-form h2{
        font-weight: 400;
    }
    .banner-header{
        height: 450px;
        width: 100%;
        object-fit: cover;
        object-position: bottom;
    }
    .divide-two{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
    .box{
        margin: 20px 0;
    }
    .box-input label{
        widows: 100%;
    }
    .box-input input{
        width: 100%;
        text-align: center;
    }
    .box-textarea textarea{
        width: 100%;
    }
    label{
        font-size: 14px;
    }
    input{
        padding: 7px;
    }
    .btn-container{
        display: grid;
        justify-content: center;
    }
    .btn-container button{
        background: #c77223;
        padding: 10px 45px ;
        margin: 15px 0;
        border-radius: 12px;
        color: white;
        border: none;

    }
     @media (min-width: 2200px){
        .banner-landing{
             background: #415B37;
        }
        
    }
    @media (max-width: 2500px){
        .banner-landing{
            height: 900px;
             
        }
        .container-landing-page{
            width: 100%;
            padding: 10px 250px 0 250px;
            
        }
    }
    @media (max-width: 1500px){
        .banner-landing{
            height: 800px;
        }
        .container-landing-page{
            width: 100%;
            padding: 100px 250px 0 250px;
        }
    }
    @media (max-width: 1400px){
        .banner-landing{
            height: 700px;
        }
        .container-landing-page{
            width: 100%;
            padding: 100px 250px 0 250px;
        }
    }
     @media (max-width: 1350px){
        .banner-landing{
            height: 650px;
        }
        .container-landing-page{
            width: 100%;
            padding: 200px 250px 0 250px;
        }
    }
     @media (max-width: 1200px){
        .banner-landing{
            height: 600px;
        }
        .container-landing-page{
            width: 100%;
            padding: 250px 250px 0 250px;
        }
    }
    @media (max-width: 980px){
        .book{
            width: 400px;
        }
        .container-form{
            width: 100%;
            padding: 15px;
        }
        .container-banner{
            grid-template-columns: 1fr;
            text-align: center;
            background-color: #415B37;
        }
        .container-banner>div:nth-child(1){
            order: 2;
            background: #415B37;
            padding: 45px;
        }
        .container-banner>div:nth-child(2){
            order: 1;
            background: #415B37;
        }
        .container-landing-page{
            padding: 25px;
        }
        .banner-landing{
            height: 1000px;
        }
        .data-list{
            grid-template-columns: 1fr;
            
        }
    }
    
    @media (max-width: 560px){
        .book{
            width: 90%;
        }
        .divide-two{
            grid-template-columns: 1fr;
        }
    }
</style>