<template>
   <div class="container-building">
       <div>
           <img class="img-building" src="../../assets/projects/building.png" alt="">
       </div>
        <div>
            <h1>Construye Tu Sueños</h1>
            <p>
                Contamos con un equipo de profesionales que le acompañará paso a paso en la construcción de su proyecto a medida.
            </p>
            <div class="building-icons">
                <div>
                    <img src="../../assets/projects/icon-home.png" alt="">
                    <h4>Arquitectura</h4>
                </div>
                <div>
                    <img src="../../assets/projects/icon-pc.png" alt="">
                    <h4>Diseño de interiores</h4>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    export default {
        name:'BuildingComponent'
    }
</script>

<style scoped>
    .container-building{
        margin: 2rem 150px 4rem 150px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .img-building{
        width: 350px;
    }
    .building-icons{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .building-icons>div{
        display: grid;
        grid-template-columns: 0.7fr 1fr;
    }
    @media (max-width: 900px){
        .container-building{
            grid-template-columns: 1fr;
            margin: 0 15px;
        }   
        .building-icons{
            grid-template-columns: 1fr;
        }
    }
</style>