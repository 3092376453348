<template>
    <div v-if="enviado.data || enviado.success" class="container-loading">
        <div class="content-loading">
            <font-awesome-icon v-show="enviado.data" icon="spinner" spin style="font-size:55px;color:white;" />
            <font-awesome-icon v-show="enviado.success" :icon="['fas', 'check']" style="font-size:55px;color:white;" />
            <p v-show="enviado.data">Enviado tu mensaje </p>
            <p v-show="enviado.success">Tu mensaje se envio con exito </p>
        </div>
    </div>
</template>

<script>
    export default {
        props:['enviado']
    }
</script>

<style scoped>
    .container-loading{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.555);
        z-index: 9999999;
        display: grid;
        justify-content: center;
        align-content: center;
    }
    .content-loading{
        background: #415B37;
        padding: 25px;
        display: grid;
        justify-items: center;
        color: white;
    }
</style>