<template>
    <div class="title-container">
        <h2>PROYECTOS EN CARTERA</h2>
        <p class="description-title">
            Nos especializamos en ofrecer proyectos en donde parece un sueño vivir, rodeados de naturaleza, vistas a los nevados y montañas, acceso a ríos y lagunas, donde puedes desarrollar tu proyecto de casa de campo, cabañas, bungalós, rodeados de áreas verdes.
        </p>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
    .title-container{
        display: grid;
        justify-content: center;
        margin: 35px 0 0 0;
        text-align: center;
    }
    .title-container h2{
        font-size: 25px;
        color: #415B37;
    }
    .description-title{
        width: 700px;
    }
    @media (max-width: 900px){
        .description-title{
            width: 400px;
        }   
    }
</style>